<template>
    <div>
        <p>Upload File To</p>

        <div v-if="!isEmployee">
            <ul class="simple_list">
                <li>
                    <label><input type='radio' value='tracker' name='params[handler]' v-model="context"> Order</label>
                </li>
                <li>
                    <label><input type='radio' value='quote' name='params[handler]' v-model="context"> Quote</label>
                </li>
            </ul>
        </div>
        <div v-else>
            <ul class="simple_list">
                <li>
                    <label><input type='radio' value='tracker' name='params[handler]' v-model="context"> Order</label>
                </li>
                <li>
                    <label><input type='radio' value='quote' name='params[handler]' v-model="context"> Quote</label>
                </li>

                <li v-if="$appStore.hasRole($cdiVars.CDI_ROLE_ENG)">
                    <label><input type='radio' value='curb' name='params[handler]' v-model="context"> Item</label>
                </li>
                <li v-if="$appStore.hasRole($cdiVars.CDI_ROLE_ENG)">
                  <label><input type='radio' value='curb_conforming' name='params[handler]' v-model="context"> Item Conforming</label>
                </li>
                <li v-if="$appStore.hasRole($cdiVars.CDI_ROLE_ENG)">
                    <label><input type='radio' value='eco' name='params[handler]' v-model="context"> ECO</label>
                </li>
                <li v-if="$appStore.hasRole($cdiVars.CDI_ROLE_ENG)">
                    <label><input type='radio' value='part' name='params[handler]' v-model="context"> Part</label>
                </li>
                <li v-if="$appStore.hasRole($cdiVars.CDI_ROLE_ENG)">
                    <label><input type='radio' value='model' name='params[handler]' v-model="context"> Model Importer</label>
                </li>
                <li v-if="$appStore.hasRole($cdiVars.CDI_ROLE_ENG)">
                    <label><input type='radio' value='batch_utilization_data' name='params[handler]' v-model="context"> Batch Utilization Data</label>
                </li>

                <li v-if="$appStore.hasRole($cdiVars.CDI_ROLE_QC)">
                    <label><input type='radio' value='company' name='params[handler]' v-model="context"> Company</label>
                </li>

                <li v-if="$appStore.hasRole([$cdiVars.CDI_ROLE_HR, $cdiVars.CDI_ROLE_MANAGER])">
                    <label><input type='radio' value='user' name='params[handler]' v-model="context"> User</label>
                </li>

                <li v-if="$appStore.hasRole([$cdiVars.CDI_ROLE_ACCOUNTING, $cdiVars.CDI_ROLE_SHOP_SUP])">
                    <label><input type='radio' value='po' name='params[handler]' v-model="context"> PO</label>
                </li>
                <li v-if="$appStore.hasRole([$cdiVars.CDI_ROLE_ACCOUNTING, $cdiVars.CDI_ROLE_SHOP_SUP])">
                    <label><input type='radio' value='asset' name='params[handler]' v-model="context"> Asset</label>
                </li>

                <li v-if="$appStore.hasRole($cdiVars.CDI_ROLE_SHOP_SUP)">
                    <label><input type='radio' value='curb_training_documents' name='params[handler]' v-model="context"> Curb Training Documents</label>
                </li>

                <li v-if="$appStore.hasRole([$cdiVars.CDI_ROLE_SALES, $cdiVars.CDI_ROLE_ACCOUNTING, $cdiVars.CDI_ROLE_QC, $cdiVars.CDI_ROLE_LEADERSHIP, $cdiVars.CDI_ROLE_ENG])">
                    <label><input type='radio' value='tracker_mer' name='params[handler]' v-model="context"> MER</label>
                </li>

                <li v-if="$appStore.isStaffExcludingShop()">
                    <label><input type='radio' value='item_batch' name='params[handler]' v-model="context"> Item Batch</label>
                </li>
                <li v-if="$appStore.isStaffExcludingShop()">
                    <label><input type='radio' value='shipping_data' name='params[handler]' v-model="context"> Shipping Data</label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import { store } from '@/store/BusinessObject.store.js';

    export default {
        name: "CDIFile",
        data() {
            return {
                state: fileUploadStore.state,
                appState: this.$appStore.state,
                context: null,
                objectState: store.state,

            }
        },
        computed: {
            isEmployee: function () {
                return this.appState.user.userTypeID === this.$cdiVars.USER_TYPE_EMPLOYEE;
            }
        },
        watch: {
            context: function () {
                this.$appStore.setFileUploadContext(this.context);
                let id = this.objectState.object.id;
                let view = this.objectState.currentView;
                if (this.context === 'curb'){
                  this.state.referenceID = id;
                  this.$appStore.setFileUploadCallback(function () {
                    store.load(id, view);
                  });
                }
            }
        }
    }
</script>

<style scoped>

</style>